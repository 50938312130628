import { FC, useRef, useEffect, useState } from "react";
import useWindowDimensions from "../../Hooks/ScreenDimensionsHook";
import { HexagonCard } from "./HexagonCard";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useWindowHeight from "../../Hooks/ScreenHeight";

gsap.registerPlugin(ScrollTrigger);

export const HexagonGrid: FC = () => {
  const width = useWindowDimensions();
  const isMobile = width < 768;
  const blockWidth = isMobile ? width / 3.5 : width / 5;
  const left = isMobile ? -(width / 10) : -(width / 10);
  const top = blockWidth - blockWidth * 0.15;
  const top2 = top * 2;
  const top3 = top * 3;
  const top4 = top * 4;
  const left2 = width / 22;
  const height = useWindowHeight();
  const [isLandscape, setIsLandscape] = useState<boolean>(false);
  console.log("====================================");
  console.log(height, "px", isLandscape);
  console.log("====================================");
  useEffect(() => {
    const handleResize = () => {
      // Перевірка на ландшафтний режим
      setIsLandscape(width < 855 && height < 400);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width, height]);
  const hexagonRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    gsap.fromTo(
      hexagonRefs.current,
      { y: 50, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        stagger: 0.2,
        scrollTrigger: {
          trigger: hexagonRefs.current,
          start: "top bottom", // Почати анімацію, коли елемент на 80% вниз від верхньої частини екрана
          scrub: true,
          once: true,
        },
      }
    );
  }, []);

  if (isMobile) {
    return (
      <div
        className={`relative h-max xxs:mt-[350px] xxs1:mt-[360px]  ${
          isLandscape && "mb-32"
        }`}
      >
        <div
          className={`flex flex-row justify-center absolute top-0 `}
          style={{ left }}
        >
          <div ref={(el) => (hexagonRefs.current[0] = el)}>
            <HexagonCard text={""} width={blockWidth} />
          </div>
          <div ref={(el) => (hexagonRefs.current[1] = el)}>
            <HexagonCard text={"Hedge Funds"} width={blockWidth} />
          </div>
          <div ref={(el) => (hexagonRefs.current[2] = el)}>
            <HexagonCard text={"Trading Platforms"} width={blockWidth} />
          </div>
          <div ref={(el) => (hexagonRefs.current[3] = el)}>
            <HexagonCard text={""} width={blockWidth} />
          </div>
        </div>
        <div
          className={`flex flex-row absolute justify-center`}
          style={{ top, left: left2 }}
        >
          <div ref={(el) => (hexagonRefs.current[4] = el)}>
            <HexagonCard text={"Risk Management"} width={blockWidth} />
          </div>
          <div ref={(el) => (hexagonRefs.current[5] = el)}>
            <HexagonCard
              text={"Our Portfolio"}
              width={blockWidth}
              isStrokeWidth={false}
              isFontSize={false}
            />
          </div>
          <div ref={(el) => (hexagonRefs.current[6] = el)}>
            <HexagonCard text={"Institutional"} width={blockWidth} />
          </div>
          <div ref={(el) => (hexagonRefs.current[7] = el)}>
            <HexagonCard text={""} width={blockWidth} />
          </div>
        </div>
        <div className={`flex flex-row absolute`} style={{ left, top: top2 }}>
          <div ref={(el) => (hexagonRefs.current[8] = el)}>
            <HexagonCard text={""} width={blockWidth} />
          </div>
          <div ref={(el) => (hexagonRefs.current[9] = el)}>
            <HexagonCard text={"Retail"} width={blockWidth} />
          </div>
          <div ref={(el) => (hexagonRefs.current[10] = el)}>
            <HexagonCard text={"Market Making"} width={blockWidth} />
          </div>
          <div ref={(el) => (hexagonRefs.current[11] = el)}>
            <HexagonCard text={"Brokers"} width={blockWidth} />
          </div>
        </div>

        <div
          className={`flex flex-row absolute justify-center left-6`}
          style={{ top: top3, left: left2 }}
        >
          <div ref={(el) => (hexagonRefs.current[12] = el)}>
            <HexagonCard text={"Integrations"} width={blockWidth} />
          </div>
          <div ref={(el) => (hexagonRefs.current[13] = el)}>
            <HexagonCard text={"High Frequency Trading"} width={blockWidth} />
          </div>
          <div ref={(el) => (hexagonRefs.current[14] = el)}>
            <HexagonCard text={"Order Books"} width={blockWidth} />
          </div>
          <div ref={(el) => (hexagonRefs.current[15] = el)}>
            <HexagonCard text={""} width={blockWidth} />
          </div>
        </div>
        <div className={`flex flex-row absolute`} style={{ left, top: top4 }}>
          <div ref={(el) => (hexagonRefs.current[16] = el)}>
            <HexagonCard text={""} width={blockWidth} />
          </div>
          <div ref={(el) => (hexagonRefs.current[17] = el)}>
            <HexagonCard text={"Exchanges"} width={blockWidth} />
          </div>
          <div ref={(el) => (hexagonRefs.current[18] = el)}>
            <HexagonCard text={"Strategy Optimisation"} width={blockWidth} />
          </div>
          <div ref={(el) => (hexagonRefs.current[19] = el)}>
            <HexagonCard text={""} width={blockWidth} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`relative flex justify-center xxl:mb-[1150px] xxxl:mb-[0%] xxl0:h-[50vh] ${
        isLandscape && "mt-[100px]"
      }`}
    >
      <div className={`flex flex-row absolute top-0`}>
        <div ref={(el) => (hexagonRefs.current[0] = el)}>
          <HexagonCard text={""} width={blockWidth} />
        </div>
        <div ref={(el) => (hexagonRefs.current[1] = el)}>
          <HexagonCard text={"Hedge Funds"} width={blockWidth} />
        </div>
        <div ref={(el) => (hexagonRefs.current[2] = el)}>
          <HexagonCard text={"Trading Platforms"} width={blockWidth} />
        </div>
        <div ref={(el) => (hexagonRefs.current[3] = el)}>
          <HexagonCard text={"Brokers"} width={blockWidth} />
        </div>
        <div ref={(el) => (hexagonRefs.current[4] = el)}>
          <HexagonCard text={"High Frequency Trading"} width={blockWidth} />
        </div>
        <div ref={(el) => (hexagonRefs.current[5] = el)}>
          <HexagonCard text={""} width={blockWidth} />
        </div>
      </div>
      <div className={`flex flex-row justify-center absolute`} style={{ top }}>
        <div className="flex flex-row absolute z-[2]">
          <div ref={(el) => (hexagonRefs.current[6] = el)}>
            <HexagonCard text={"Risk Management"} width={blockWidth} />
          </div>
          <div ref={(el) => (hexagonRefs.current[7] = el)}>
            <HexagonCard
              text={"Our Portfolio"}
              width={blockWidth}
              isStrokeWidth={false}
              isBg={true}
            />
          </div>
          <div ref={(el) => (hexagonRefs.current[8] = el)}>
            <HexagonCard text={"Institutional"} width={blockWidth} />
          </div>
          <div ref={(el) => (hexagonRefs.current[9] = el)}>
            <HexagonCard text={"Order Books"} width={blockWidth} />
          </div>
          <div ref={(el) => (hexagonRefs.current[10] = el)}>
            <HexagonCard text={"Exchanges"} width={blockWidth} />
          </div>
        </div>
      </div>
      <div
        className={`flex flex-row justify-center absolute`}
        style={{ top: top2 }}
      >
        <div ref={(el) => (hexagonRefs.current[11] = el)}>
          <HexagonCard text={""} width={blockWidth} />
        </div>
        <div ref={(el) => (hexagonRefs.current[12] = el)}>
          <HexagonCard text={"Retail"} width={blockWidth} />
        </div>
        <div ref={(el) => (hexagonRefs.current[13] = el)}>
          <HexagonCard text={"Market Making"} width={blockWidth} />
        </div>
        <div ref={(el) => (hexagonRefs.current[14] = el)}>
          <HexagonCard text={"Integrations"} width={blockWidth} />
        </div>
        <div ref={(el) => (hexagonRefs.current[15] = el)}>
          <HexagonCard text={"Strategy Optimisation"} width={blockWidth} />
        </div>
        <div ref={(el) => (hexagonRefs.current[16] = el)}>
          <HexagonCard text={""} width={blockWidth} />
        </div>
      </div>
    </div>
  );
};
