import UserPhoto from "../assets/Image/UserPhoto1.png";

export const clientsInformation = [
  {
    image: UserPhoto,
    description:
      "“QuantLabs has met our tech needs for the past year. Their team is always responsive, reliable, and eager to assist with any questions that come up”",
    name: "Stephen Morgan",
    position: "Ocupation",
  },
  {
    image: UserPhoto,
    description:
      "“QuantLabs has met our tech needs for the past year. Their team is always responsive, reliable, and eager to assist with any questions that come up”",
    name: "Stephen Morgan",
    position: "Ocupation",
  },
  {
    image: UserPhoto,
    description:
      "“QuantLabs has met our tech needs for the past year. Their team is always responsive, reliable, and eager to assist with any questions that come up”",
    name: "Stephen Morgan",
    position: "Ocupation",
  },
  {
    image: UserPhoto,
    description:
      "“QuantLabs has met our tech needs for the past year. Their team is always responsive, reliable, and eager to assist with any questions that come up”",
    name: "Stephen Morgan",
    position: "Ocupation",
  },
  {
    image: UserPhoto,
    description:
      "“QuantLabs has met our tech needs for the past year. Their team is always responsive, reliable, and eager to assist with any questions that come up”",
    name: "Stephen Morgan",
    position: "Ocupation",
  },
  {
    image: UserPhoto,
    description:
      "“QuantLabs has met our tech needs for the past year. Their team is always responsive, reliable, and eager to assist with any questions that come up”",
    name: "Stephen Morgan",
    position: "Ocupation",
  },
  {
    image: UserPhoto,
    description:
      "“QuantLabs has met our tech needs for the past year. Their team is always responsive, reliable, and eager to assist with any questions that come up”",
    name: "Stephen Morgan",
    position: "Ocupation",
  },
  {
    image: UserPhoto,
    description:
      "“QuantLabs has met our tech needs for the past year. Their team is always responsive, reliable, and eager to assist with any questions that come up”",
    name: "Stephen Morgan",
    position: "Ocupation",
  },
];
