import React from "react";

interface ServiceDetailsProps {
  title: string;
  description: string;
}

export const ServiceDetails: React.FC<ServiceDetailsProps> = ({
  title,
  description,
}) => (
  <div className="relative group p-14 flex flex-col justify-center lg2:px-8 items-center gap-3 sm:gap-1 md:p-6 scale-100 transition-transform duration-300 transform group-hover:scale-105">
    <div className="text-center text-white text-[32px] md:text-sm lg2:text-[20px] font-manrope font-medium leading-[40px] lg2:leading-[28px] whitespace-normal">
      {title}
    </div>
    <div className="text-center text-pretty text-gray-400 lg2:text-[12px] text-[14px] md:text-[10px] xxs:text-[12px] font-medium whitespace-normal">
      {description}
    </div>
  </div>
);
