import React, { InputHTMLAttributes, useEffect, useState } from "react";
import useWindowHeight from "../../Hooks/ScreenHeight";
import useWindowDimensions from "../../Hooks/ScreenDimensionsHook";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  id: string;
}

const CustomInput: React.FC<InputProps> = ({ label, id, ...props }) => {
  const height = useWindowHeight();
  const width = useWindowDimensions();
  const [isLandscape, setIsLandscape] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(width < 945 && height < 440);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width, height]);
  
  return (
    <div className="w-full flex flex-col">
      <label
        className="text-white text-base font-medium leading-normal sm:text-sm"
        htmlFor={id}
      >
        {label}
      </label>
      <input
        id={id}
        className={`w-full p-4 border-b ${isLandscape && 'p-2 text-sm'} border-white rounded-none bg-transparent text-gray-300 text-lg font-medium leading-relaxed focus:outline-none`}
        {...props}
      />
    </div>
  );
};

export default CustomInput;
