import React, { useRef, useEffect, useState } from "react";
import { Button } from "../Buttons/Button";
import gsap from "gsap";
import useWindowDimensions from "../../Hooks/ScreenDimensionsHook";
import useWindowHeight from "../../Hooks/ScreenHeight";

export const Hero: React.FC = () => {
  const headingRef = useRef<HTMLHeadingElement | null>(null);
  const subheadingRef = useRef<HTMLHeadingElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const width = useWindowDimensions();
  const height = useWindowHeight();
  const isMobile = width < 768;
  const [isLandscape, setIsLandscape] = useState<boolean>(false);
  console.log("====================================");
  console.log(height, "px", isLandscape);
  console.log("====================================");
  useEffect(() => {
    const handleResize = () => {
      // Перевірка на ландшафтний режим
      setIsLandscape(width < 855 && height < 400);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width, height]);

  useEffect(() => {
    gsap.fromTo(
      headingRef.current,
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 1 }
    );
    gsap.fromTo(
      subheadingRef.current,
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 1, delay: 0.2 }
    );
    gsap.fromTo(
      buttonRef.current,
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 1, delay: 0.2 }
    );
  }, []);

  return (
   <>
      <div className="sm:hidden md:hidden w-96 h-96 absolute right-[-220px] z-[0] top-[-240px] bg-gradient-to-br from-darkSky to-darkSky2 rounded-full blur-3xl z-1" />
      <div className={`absolute w-full left-[50%] ${isLandscape && 'top-[60%]'} top-[50%] translate-x-[-50%] translate-y-[-50%] `}>
        <div className="flex flex-col justify-center text-center items-center gap-6 ">
          <h1
            ref={headingRef}
            className={`text-6xl md:text-4xl xxs:text-[43px] xxs1:text-4xl font-bold text-white ${
              isLandscape && "text-[40px] "
            }`}
          >
            Your Trusted Technology Partner
          </h1>
          <h3
            ref={subheadingRef}
            className="text-lg md:text-sm font-normal text-white max-w-[690px] sm:max-w-80"
          >
            Bridging the gap between tradition and disruption, bringing
            institutions to the forefront of today’s tech landscape one project
            at a time.
          </h3>
          {isMobile ? (
            <span
              className={`w-[50%] 
              
            flex justify-center items-center`}
              ref={buttonRef}
            >
              <Button label="Get in Touch" variant="primary" />
            </span>
          ) : (
            <span
              className="3xl:w-[60%] 
              xl:w-[60%]
              w-[40%]
              
            flex justify-center items-center xl1:text-[16px]"
              ref={buttonRef}
            >
              <Button label="Get in Touch" variant="primary" />
            </span>
          )}
        </div>
      </div>
      <div className="sm:hidden md:hidden w-96 h-96 -z-1 absolute left-[-340px] bottom-[-240px] rotate-[-107.32deg] bg-gradient-to-br from-stroukBlue to-darkSky2 rounded-full blur-3xl" />
    </>
  );
};
