import React, { useRef } from "react";
// import Logo from '../../assets/Icons/Logo';
import blueLogo from "../../assets/Icons/quantLabsLogo-blue.svg";
import Linkedin from "../../assets/Icons/Linkedin";
import Email from "../../assets/Icons/Email";
import Location from "../../assets/Icons/Location";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

export const Footer: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    if (sectionRef.current) {
      gsap.fromTo(
        ".footer-box",
        {
          opacity: 0,
          y: 700,
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.1,
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 100% xxl:top 30%",
            end: "bottom 100%",
            scrub: true,
          },
        }
      );
    }
  }, []);

  return (
    <footer
      ref={sectionRef}
      className="flex-grow overflow-hidden px-28 sm:px-4 bg-[#01060f] w-full md:p-8 p-16 flex md:flex-col flex-row justify-between xxl:justify-evenly items-start md:items-center gap-12 sm:gap-10"
    >
      <div className="flex flex-col  items-start md:items-center gap-4 footer-box">
        <div className="relative ">
          <img src={blueLogo} alt="QuantLabs logo" className="w-[15em]" />
          {/* <Logo /> */}
          <div className="sm:hidden md:hidden w-96 h-96 absolute left-[-250px] bottom-[-450px]  rotate-[-107.32deg] bg-gradient-to-br from-blue-400 to-blue-600 rounded-full blur-3xl" />
        </div>
        <div className="text-zinc-400 text-lg font-medium leading-relaxed">
          Professional, Reliable, Fast
        </div>
      </div>

      <div className="flex flex-col items-start md:items-center gap-10 sm:gap-6 footer-box">
        <div className="text-white text-2xl font-medium leading-loose">
          Navigation
        </div>
        <nav className="flex flex-col items-start md:items-center gap-6 sm:gap-3">
          <a
            href="#home"
            className="text-white text-base font-medium leading-normal"
          >
            Home
          </a>
          <a
            href="#about"
            className="text-white text-base font-medium leading-normal"
          >
            About Us
          </a>
          <a
            href="#services"
            className="text-white text-base font-medium leading-normal"
          >
            Services
          </a>
          <a
            href="#contact"
            className="text-white text-base font-medium leading-normal"
          >
            Contact Us
          </a>
        </nav>
      </div>

      <div className="flex flex-col items-start md:items-center gap-10 sm:gap-6 footer-box">
        <div className="text-white text-2xl font-medium leading-loose">
          Get in touch
        </div>
        <div className="flex flex-col items-start md:items-center gap-6 sm:gap-3">
          <div className="flex items-start md:items-center gap-3">
            <Linkedin />
            <a
              href="https://linkedin.com"
              className="text-white text-base font-medium leading-normal"
            >
              LinkedIn
            </a>
          </div>
          <div className="flex items-start md:items-center gap-3">
            <Email />
            <a
              href="mailto:info@quantlabs.com"
              className="text-white text-base font-medium leading-normal"
            >
              info@quantlabs.com
            </a>
          </div>
          <div className="flex items-start md:items-center gap-3">
            <Location />
            <span className="text-white text-base font-medium leading-normal">
              London, Miami, Dubai
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};
