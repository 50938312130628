import React, { useRef } from "react";
import { WhyUsCard } from "./WhyUsCard";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import useWindowDimensions from "../../Hooks/ScreenDimensionsHook";

gsap.registerPlugin(ScrollTrigger);

export const WhyUsSection: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const width = useWindowDimensions();
  const isMobile = width < 1440;
  useGSAP(() => {
    if (sectionRef.current) {
      const cards = sectionRef.current.querySelectorAll(".why-us-card");

      gsap.fromTo(
        cards,
        {
          opacity: 0,
          x: -50,
          duration: 0.7,
          stagger: 0.3,
          ease: "power3.out",
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 80%",
            scrub: true,
          },
        },
        {
          opacity: 1,
          x: 0,
          duration: 2,
          stagger: 0.3,
          ease: "power3.out",
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 80%",
            scrub: true,
          },
        }
      );
      gsap.fromTo(
        ".heading",
        {
          opacity: 0,
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 80%",
            end: "top 55%",
            scrub: true,
          },
        },
        {
          opacity: 1,
          duration: 2,
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 80%",
            end: "top 60%",
            scrub: true,
          },
        }
      );
    }
  }, []);

  return (
    <div
      className={`w-full h-auto flex-col justify-start items-center ${
        isMobile ? "gap-12" : "gap-6"
      }`}
    >
      <div className="text-white text-5xl sm:text-[32px] font-bold leading-10 mb-14 heading">
        Why us?
      </div>
      <div
        className="lg:flex-col flex-row justify-start items-start gap-6 flex"
        ref={sectionRef}
      >
        <div className={`why-us-card ${!isMobile && "w-[33%]"}`}>
          <WhyUsCard
            title="Exceptional Service"
            description="Our firm is founded on professionalism, reliability, and efficiency."
            iconNumber={1}
          />
        </div>
        <div className={`why-us-card ${!isMobile && "w-[33%]"}`}>
          <WhyUsCard
            title="End-to-End Tech"
            description="We strive to be your all-in-one tech partner, crafting bespoke solutions to the highest standards."
            iconNumber={2}
          />
        </div>
        <div className={`why-us-card ${!isMobile && "w-[33%]"}`}>
          <WhyUsCard
            title="Client-Centric Approach"
            description="We treat each client as a long-term partner, dedicated to ensuring lasting mutual success."
            iconNumber={3}
          />
        </div>
      </div>
    </div>
  );
};

export default WhyUsSection;
