import React, { useRef } from "react";
import Code from "../../assets/Image/Code.png";
import { CustomImage } from "../CustomImage/CustomImage";
import useWindowDimensions from "../../Hooks/ScreenDimensionsHook";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

export const WhoWeAre: React.FC = () => {
  const text =
    "QuantLabs is a team of experienced quants, developers, engineers, and designers from some of the most reputable firms in the industry. With experience spanning from different financial institutions, we bring together years of technical skills to your project.";
  const width = useWindowDimensions();
  const isMobile = width < 768;

  const sectionRef = useRef<HTMLDivElement>(null);

  const preventSingleWordWrap = (text: string) => {
    const words = text.split(" ");
    if (words.length > 2) {
      return (
        words.slice(0, -2).join(" ") + "\u00A0" + words.slice(-2).join("\u00A0")
      );
    }
    return text;
  };

  useGSAP(() => {
    gsap.fromTo(
      ".animate-text",
      {
        opacity: 0,
        x: -500,
      },
      {
        opacity: 1,
        x: 0,
        duration: 3,
        stagger: 0.3,
        ease: "power3.out",
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 70%",
          end: "bottom 20%",
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      ".parallax-image",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 3,
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 100%",
          end: "bottom 50%",
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <div
      className="w-full h-auto flex flex-row md:flex-col items-center justify-start md:justify-center gap-6 relative"
      ref={sectionRef}
    >
      <div className="animate-text w-full flex flex-col justify-start items-start gap-6">
        <div className="self-stretch text-white text-5xl sm:text-[32px] font-bold leading-10">
          Who we are
        </div>
        {isMobile ? (
          <div className="w-full h-auto bg-slate-950/opacity-80">
            <CustomImage
              className="w-full h-full parallax-image"
              src={Code}
              alt={"Code"}
            />
          </div>
        ) : (
          <>
            <div className="self-stretch text-zinc-400 text-xl lg:text-lg font-normal leading-[30px]">
              {preventSingleWordWrap(text)}
            </div>
            <div className="sm:hidden md:hidden w-96 h-96 absolute left-[-300px] bottom-[-300px] bg-gradient-to-br from-stroukBlue to-darkSky2 rounded-full blur-3xl" />
          </>
        )}
      </div>

      {isMobile ? (
        <div className="animate-text self-stretch text-zinc-400 text-md font-normal leading-tight">
          QuantLabs is a team of experienced quants, developers, engineers, and
          designers from some of the most reputable firms in the industry. With
          experience spanning from different financial institutions, we bring
          together years of technical skills to your project.
        </div>
      ) : (
        <div className="w-full md:w-full h-auto bg-slate-950/80">
          <CustomImage
            className="w-full h-full object-cover parallax-image"
            src={Code}
            alt="Code"
          />
        </div>
      )}
    </div>
  );
};
