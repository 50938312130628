interface SectionProps {
    id?: string;
    additionalClasses?: string;
    children: React.ReactNode;
  }
  
  export const Section: React.FC<SectionProps> = ({ id, additionalClasses = '', children }) => {
    return (
      <section id={id} className={`w-screen ${additionalClasses}`}>
        {children}
      </section>
    );
  };