import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Quote from "../../assets/Image/Quote.png";
import { clientsInformation } from "../../Data/ClientsData";
import { CustomImage } from "../CustomImage/CustomImage";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import useWindowHeight from "../../Hooks/ScreenHeight";
import useWindowDimensions from "../../Hooks/ScreenDimensionsHook";

export const ClientsTestimonials: React.FC = () => {
  const height = useWindowHeight();
  const width = useWindowDimensions();
  const [isLandscape, setIsLandscape] = useState<boolean>(false);
  console.log("====================================");
  console.log(height, "px", isLandscape);
  console.log("====================================");
  useEffect(() => {
    const handleResize = () => {
      // Перевірка на ландшафтний режим
      setIsLandscape(width < 855 && height < 400);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width, height]);
  const sliderRef = useRef<Slider>(null);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          rows: 2,
          slidesPerRow: 2,
        },
      },
    ],
  };

  const sectionRef = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    if (sectionRef.current) {
      gsap.fromTo(
        ".testimonials",
        {
          opacity: 0,
          x: 0,
        },
        {
          opacity: 1,
          x: 0,
          duration: 2.5,
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 100%",
            end: "bottom 80%",
            scrub: true,
          },
        }
      );
    }
  }, []);

  return (
    <div
      ref={sectionRef}
      className={`relative w-full h-auto testimonials xxxl:pt-[14%] ${
        isLandscape && "mt-[20%]"
      }`}
    >
      <div className="sm:hidden md:hidden w-96 h-96 absolute right-[-180px] bottom-[380px] bg-gradient-to-br from-stroukBlue to-darkSky2 rounded-full blur-3xl" />
      <div className="px-28 sm:px-4 mb-8">
        <div className="text-white text-5xl sm:text-[32px] font-bold leading-snug">
          Clients Testimonials
        </div>
      </div>
      <div className="sm:pl-4 pl-10">
        <Slider {...settings} ref={sliderRef}>
          {clientsInformation.map((company, index: number) => (
            <div key={index} className="xxs:pb-4 xxs:pr-4 pr-6 gap-4">
              <div className="h-[400px] xxs:h-[330px] p-6  sm:p-4 pb-1 rounded-2xl border border-sky-500 flex flex-col justify-between xxs:justify-start xxs:gap-3 hover:bg-gradient-to-tl from-[#073A63] via-[rgba(34,112,174,0.52)] to-[rgba(36,120,193,0.05)]">
                <div className="text-[#CCC] text-base font-normal sm:text-sm">
                  <CustomImage
                    className="!max-w-[45px] max-h-[65px] pb-4"
                    src={Quote}
                    alt="Circle"
                  />
                  {company.description}
                </div>

                <div className="flex items-center gap-3">
                  <img
                    className="w-10 h-10 rounded-full"
                    src={company.image}
                    alt={company.name}
                  />

                  <div className="flex flex-col">
                    <div className="text-white text-sm font-semibold">
                      {company.name}
                    </div>
                    <div className="text-white text-xs font-normal">
                      {company.position}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
