import { useState, useEffect } from "react";

function getWindowHeight(): number {
  if (typeof window !== "undefined") {
    return window.innerHeight;
  }
  // Значення за замовчуванням для серверного рендерингу або інших середовищ
  return 0;
}

export default function useWindowHeight(): number {
  const [windowHeight, setWindowHeight] = useState<number>(getWindowHeight());

  useEffect(() => {
    function handleResize() {
      setWindowHeight(getWindowHeight());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowHeight;
}
