import { DualSolutions } from "../../components/DualSolutions/DualSolutions";
import { OurDevelopmentPipeline } from "../../components/OurDevelopmentPipeline/OurDevelopmentPipeline";
import { OurEdge } from "../../components/OurEdge/OurEdge";
import { WhoWeAre } from "../../components/WhoWeAre/WhoWeAre";
import { WhyUsSection } from "../../components/WhyUs/WhyUs";
import { features } from "../../Data/OurEdgeData";
import { CompaniesSlider } from "../../components/CompaniesSlider/CompaniesSlider";
import { HexagonGrid } from "../../components/Hexagon/HexagonGrid";
import { InformationSection } from "../../components/Information/InformationSection";
import { Services } from "../../components/Services/Services";
import { ClientsTestimonials } from "../../components/ClientsTestimonials/ClientsTestimonials";
import { ContactUsForm } from "../../components/ContactUs/ContactUsForm";
import { Section } from "../../types/Section/Section";
import { useEffect, useState } from "react";
import useWindowHeight from "../../Hooks/ScreenHeight";
import useWindowDimensions from "../../Hooks/ScreenDimensionsHook";

export const Main: React.FC = () => {
  const height = useWindowHeight();
  const width = useWindowDimensions();
  const [isLandscape, setIsLandscape] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(width < 945 && height < 440);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width, height]);
  
  return (
    <main className="bg-[#01060f] flex-grow overflow-hidden relative">
      <Section additionalClasses="pb-48 pt-10 sm:pb-32 px-28 sm:px-4 max-w-[1600px] mx-auto">
        <InformationSection />
      </Section>

      <Section
        id="about"
        additionalClasses="pl-28 sm:px-4 pb-48 sm:pb-32 max-w-[1600px] mx-auto"
      >
        <WhoWeAre />
      </Section>
      <Section additionalClasses="px-28 sm:px-4 pb-48 sm:pb-32 max-w-[1600px] mx-auto">
        <OurEdge features={features} />
      </Section>
      <Section additionalClasses="px-28 sm:px-4 pb-48 sm:pb-32 max-w-[1600px] mx-auto">
        <WhyUsSection />
      </Section>
      <Section additionalClasses="px-28 sm:px-4 pb-48 sm:pb-32 max-w-[1600px] mx-auto">
        <OurDevelopmentPipeline />
      </Section>
      <Section
        id="services"
        additionalClasses="h-screen  xxl:max-h-[60vh] lg2:px-24 px-28 sm:px-4 xxs1:pb-[120px] pb-52  md:pb-20 sm:pb-32 xs:pb-0 xs:max-h-[650px] max-w-[1600px] mx-auto"
      >
        <Services />
      </Section>
      <Section additionalClasses="3xl:h-screen sm:max-h-[60vh] lg2:max-h-[60vh] xxxl:mb-[15%] 4xl:mb-64 sm:pb-12 3xl:pb-12 xxs:mb-[20px] xxl0:pb-64 ">
        <HexagonGrid />
      </Section>
      <Section additionalClasses="h-screen xxl:max-h-[50vh] xl:max-h-[50vh] sm:px-4  sm:p-32 max-w-[1600px] mx-auto flex 3xl:justify-center xxl:justify-center ">
        <DualSolutions />
      </Section>
      <Section additionalClasses="pb-48 sm:pb-32 max-w-[1600px] mx-auto 3xl:pt-10 xxs:pt-20 4xl:mt-48">
        <ClientsTestimonials />
      </Section>
      <Section additionalClasses="pb-12 sm:pb-32 xxs:pb-28 sm:pl-4 max-w-[1600px] mx-auto">
        <CompaniesSlider />
      </Section>
      <Section
        id="contact"
        additionalClasses={`px-28 sm:px-4 pb-48 ${isLandscape && 'pb-8 pt-10'} sm:pb-32 xxs:pb-20 max-w-[1600px] mx-auto`}
      >
        <ContactUsForm />
      </Section>
    </main>
  );
};
