import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Binance from "../../assets/Image/CompaniesLogo/Binance.png";
import Coinbase from "../../assets/Image/CompaniesLogo/Coinbase.png";
import Morgan from "../../assets/Image/CompaniesLogo/Morgan.png";
import BlackRock from "../../assets/Image/CompaniesLogo/BlackRock.png";
import Amundi from "../../assets/Image/CompaniesLogo/Amundi.png";
import Invesco from "../../assets/Image/CompaniesLogo/Invesco.png";
import HTX from "../../assets/Image/CompaniesLogo/Htx.png";
//import Barclays from "../../assets/Image/CompaniesLogo/Barclays.png";
import CityBank from "../../assets/Image/CompaniesLogo/Citibank.png";
//import Santander from "../../assets/Image/CompaniesLogo/Santander.png";
//import LoydBank from "../../assets/Image/CompaniesLogo/LoydBank.png";
// import HSBC from "../../assets/Image/CompaniesLogo/Hsbc.png";
import { CustomImage } from "../CustomImage/CustomImage";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

interface Company {
  name: string;
  imageUrl: any;
}

export const CompaniesSlider: React.FC = () => {
  const companies: Company[] = [
    { name: "Binance", imageUrl: Binance },
    { name: "Coinbase", imageUrl: Coinbase },
    { name: "Morgan", imageUrl: Morgan },
    { name: "BlackRock", imageUrl: BlackRock },
    { name: "Amundi", imageUrl: Amundi },
    { name: "Invesco", imageUrl: Invesco },
    { name: "HTX", imageUrl: HTX },
    //{ name: "Barclays", imageUrl: Barclays },
    { name: "CityBank", imageUrl: CityBank },
    //{ name: "Santander", imageUrl: Santander },
    //{ name: "LLoydsBank", imageUrl: LoydBank },
    //{ name: "HSBC", imageUrl: HSBC },
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
          rows: 2,
          infinite: false,
          autoplay: false,
        },
      },
    ],
  };

  const sectionRef = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    if (sectionRef.current) {
      gsap.fromTo(
        ".companies",
        {
          opacity: 0,
          x: -550,
        },
        {
          opacity: 1,
          x: 0,
          duration: 1,
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 100%",
            end: "bottom 60%",
            scrub: true,
          },
        }
      );
    }
  }, []);

  return (
    <div
      ref={sectionRef}
      className="w-full h-auto companies xxl:mt-32 xxs:mt-10 xxs1:mb-5"
    >
      <div className="justify-start items-start gap-1 inline-flex ">
        <div className="px-28 pb-10 sm:px-4 text-white text-5xl sm:text-[32px] font-bold leading-10">
          Companies We've Served
        </div>
      </div>
      <Slider {...settings}>
        {companies.map((company, index) => (
          <div
            key={index}
            className="px-8 sm:px-1 py-3.5 justify-center items-center flex"
          >
            <CustomImage
              className="w-56 h-28 xxs:w-[100px] xxs:h-[50px]"
              src={company.imageUrl}
              alt={company.name}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};
