import React, { useEffect, useRef, useState } from "react";
import CustomInput from "../CustomInput/CustomInput";
import { Button } from "../Buttons/Button";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import useWindowHeight from "../../Hooks/ScreenHeight";
import useWindowDimensions from "../../Hooks/ScreenDimensionsHook";

export const ContactUsForm: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const height = useWindowHeight();
  const width = useWindowDimensions();
  const [isLandscape, setIsLandscape] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(width < 945 && height < 440);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width, height]);

  useGSAP(() => {
    if (sectionRef.current) {
      gsap.fromTo(
        ".contact-form",
        {
          opacity: 0,
          scale: 0.5,
        },
        {
          opacity: 1,
          scale: 1,
          duration: 1,
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 100% ",
            end: "bottom 60%",
            scrub: true,
          },
        }
      );
    }
  }, []);

  return (
    <div
      ref={sectionRef}
      className={`h-auto sm:px-8 sm:py-8 xs:px-4 px-28 mb-[120px] py-[40px] ${
        isLandscape ? "b-5 my-[-70px] py-[20px]" : "py-[40px]"
      }   lg:px-12  rounded-3xl border-4 border-secondBlue backdrop-blur-xl flex flex-col justify-start items-start space-y-10 bg-custom-gradient contact-form`}
    >
      <div className="w-full flex flex-col justify-start items-start gap-3">
        <div className={`text-white text-5xl sm:text-[32px] ${isLandscape && 'text-[40px]'} font-bold leading-10 sm:text-3xl`}>
          Contact Us
        </div>
        <div className={`text-zinc-400 text-base md:text-lg ${isLandscape && 'text-base'} font-medium leading-relaxed text-start sm:text-sm`}>
          Partner with us to bring your product to life!
        </div>
      </div>
      <form className="w-full flex flex-col gap-8">
        <div className="flex flex-row md:flex-col justify-start items-start gap-8">
          <div className="w-full flex flex-col gap-4">
            <CustomInput
              label="Full Name"
              id="fullName"
              name="fullName"
              placeholder="Enter your Full Name"
            />
            <CustomInput
              label="Email"
              id="email"
              name="email"
              type="email"
              placeholder="Enter your Email"
            />
          </div>
          <div className="w-full flex flex-col gap-4">
            <CustomInput
              label="Company Name"
              id="companyName"
              name="companyName"
              placeholder="Enter your Company Name"
            />
            <CustomInput
              label="Business Sector"
              id="businessSector"
              name="businessSector"
              placeholder="Enter your Business Sector"
            />
          </div>
        </div>
        <div className="w-full flex flex-col">
          <label
            className="text-white text-base font-medium leading-normal"
            htmlFor="description"
          >
            Brief requirement description
          </label>
          <textarea
            id="description"
            name="description"
            placeholder="Enter brief description"
            className={`w-full p-4 border-b ${isLandscape && 'p-2 text-sm'} border-white bg-transparent text-gray-300 text-lg font-medium leading-relaxed focus:outline-none`}
            rows={1}
          />
        </div>
        <div className="w-full flex justify-center items-center">
          <Button label="Submit" variant="secondary" />
        </div>
      </form>
    </div>
  );
};
